#app {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#header {
  height: 60px;
  background: lightblue;
  top: 0;
  width: 100%;
  position: absolute;
}

#main {
  position: absolute;
  top: 60px;
  background: lightcoral;
  width: 100%;
  bottom: 30px;
}

#footer {
  position: absolute;
  bottom: 30px;
  background: lightgrey;
  width: 100%;
  height: 30px;
}
