/* INSERT IMAGE DIALOG */



.insert-image-dialog .upload-decision button{
	margin-bottom: 15px;
}

.insert-image-dialog .upload-decision button.browse:hover{
	background: var(--black);
	color: white;
}

.dialog.insert-image-dialog .buttons {
	text-align: right;
	padding: 0;
}

.insert-image-dialog .browse-image .browse-container{
	min-height: 30vh;
	position: relative;
	margin-bottom: 30px;
	border-radius: 10px;
	overflow: hidden;
	border: 1px dashed var(--black)
}

.insert-image-dialog .browse-image .browse-container button {
	height: 100%;
	position: absolute;
	cursor: pointer;
}

.insert-image-dialog .browse-image .browse-container button input {
	display: none;
}

.insert-image-dialog  .uppy-DragDrop-inner label {
	font-family: var(--roboto);
	font-size: 16px;
	margin-left: 10px;
	cursor: pointer;
}

.insert-image-dialog  .uppy-DragDrop-inner {
	text-align: center;
}

.insert-image-dialog  .uppy-DragDrop-inner svg {
	display: inline-block;
}

.insert-image-dialog .browse-image .image-container {
	position: relative;
	border: 1px solid var(--black);
	margin-bottom: 30px;
}

.insert-image-dialog .browse-image .image-container img {
	max-width: 100%;
}



