



/* .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused, .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
	outline: none;
	border: none !important;

} */

.ck.ck-content h2{
		font-size: 30px
}

.ck.ck-content {
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	color: #666;
	line-height: 1.4;
}

.ck.ck-content h2 {
	font-size: 36px;
	letter-spacing: -1px;
	font-weight: 500;
	color: #333;
	margin-top: 20px;
	margin-bottom: 10px;
}

.ck.ck-content h3 {
	font-size: 24px;
	line-height: 1.1;
	margin-top: 20px;
	margin-bottom: 10px;
	letter-spacing: -1px;
	font-weight: 500;
	color: #333
}

.ck.ck-content h4 {
	font-size: 20px;
	line-height: 1.1;
	margin-top: 20px;
	margin-bottom: 10px;
	letter-spacing: -1px;
	font-weight: 500;
	color: #333
}

.ck.ck-content a   {
	color: #00539c;
	text-decoration: none;
	outline: none;
	transition: 0.2 all ease;
}

.ck.ck-content figure.table {
	width: calc(100% - 10px);
	margin: 30px 0 !important;

}

.ck.ck-content figure.table td{
	vertical-align: top;
}

.ck.ck-content a:focus , .ck.ck-content a:hover, .ck.ck-content a:active {
	color:  #111;
}

 .ck.ck-content li{
	list-style-type: disc;
	list-style: disc;
	padding-left: 5px;
}

.ck.ck-content ul {
	list-style: disc;
	padding-left: 20px;
}

#website-links-1 .ck.ck-content figure.table td, #contact-zurich-2 .ck.ck-content figure.table td, #contact-schlieren-2 .ck.ck-content figure.table td{
	width: 50%;
	vertical-align: middle;
	text-align: center;
	padding: 30px;
	height: 120px;
}

.ck.ck-content figure.table {
	width: 100%;
}

#contact-zurich-1, #contact-schlieren-1 {
	max-width: 350px;
}

#contact-zurich-2 .ck.ck-content figure.table td, #contact-schlieren-2 .ck.ck-content figure.table td {
	vertical-align: top;
}

/* #contact-zurich-2 .ck.ck-content figure.image {
	height: 180px !important;
	width: auto !important;
} */

/* #contact-zurich-2 .ck.ck-content figure.image img {
	height: 100%;
	width: auto;
} */